import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { clientId, redirectUri } from "./config/global";
import 'bootstrap/dist/css/bootstrap.min.css';

import Login from "./Login";
import MyFiles from "./MyFiles";
import SharedToExternal from "./SharedToExternal";
import SharedToAnyone from "./SharedToAnyone";
import Administrator from "./Administrator";
import UserExternal from "./UserExternal";
import UserAnyone from "./UserAnyone";

const App = () => {
	return (		
    <BrowserRouter>
			<GoogleOAuthProvider 
				clientId={clientId}
				redirectUri={redirectUri}
			>
			{
				localStorage.getItem('userInfo') ? (
					<Routes>
						<Route exact path="/" element={<MyFiles />} />
						<Route path="/my-files" element={<MyFiles />} />
						<Route path="/shared-to-external" element={<SharedToExternal />} />
						<Route path="/shared-to-anyone" element={<SharedToAnyone />} />
						<Route path="/administrator" element={<Administrator />} />
						<Route path="/user-shared-to-external" element={<UserExternal />} />
						<Route path="/user-shared-to-anyone" element={<UserAnyone />} />
						<Route path="*" element={<MyFiles />} />
					</Routes>
				) : (
					<Routes>
						<Route exact path="/" element={<Login />} />
						<Route path="/login" element={<Login />} />
						<Route path="*" element={<Login />} />
					</Routes>					
				)
			}
			</GoogleOAuthProvider>
    </BrowserRouter>
	);
};

export default App;
