import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar, Dropdown, Offcanvas, Row, Col, Image, Form, InputGroup, Button, Table, Modal, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faFilter, faFile, faFilePdf, faFileImage, faFileVideo, faFileAudio, faFileZipper, faFileLines, faFolder, faEarthAmericas, faTrash, faArrowUpRightFromSquare, faCaretUp, faCaretDown, faRotateLeft, faCheck, faTriangleExclamation, faUserShield, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Select from "react-select";
import { redirectUri } from "./config/global";

const UserAnyone = () => {
	const userInfo = JSON.parse(localStorage.getItem('userInfo'));
	const tableSetting = JSON.parse(localStorage.getItem('tableSetting'));

  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(tableSetting ? tableSetting.startDate : moment().subtract(90, "days").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(tableSetting ? tableSetting.endDate : moment().format("YYYY-MM-DD"));
  const [form, setForm] = useState({
    accessToken: userInfo.access_token,
    email: userInfo.email,
    domain: userInfo.hd,
    startDate: startDate,
    endDate: endDate,
    nextPageToken: '',
    impersonate: userInfo.impersonate_email ? userInfo.impersonate_email : ''
  });

  const [sortColumn, setSortColumn] = useState(tableSetting ? tableSetting.sortColumn : 'createdTime');
  const [sortType, setSortType] = useState(tableSetting ? tableSetting.sortType : 'desc');

  const [user, setUser] = useState({});
  const [users, setUsers] = useState([]);
  const [modal, setModal] = useState({});
  const [show, setShow] = useState(false);
  const [showInfo, setShowInfo] = useState('d-block');
  const [showRemove, setShowRemove] = useState('d-none');
  const [modalMulti, setModalMulti] = useState({});
  const [showMulti, setShowMulti] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const getData = async () => {
    setIsLoading(true);
    setIsError(false);
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + userInfo.access_token,
      },
      body: JSON.stringify(form),
    };    
    await fetch(redirectUri + "/getmydrivelist/sharedtoanyonewithlink", options)
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        let data = [...tableData, ...res.values.fileList]
        for (let i in res.values.fileList) {
          res.values.fileList[i].type = categorize(res.values.fileList[i].mimeType);
        }
        setTableData(data);
        if (res.values.nextPageToken !== '') {
          let newForm = {...form}
          newForm.nextPageToken = res.values.nextPageToken;
          setForm(newForm);
        }
      } else if (res.status === 401) {
        setIsError(true);
      }
      setIsLoading(false);
    });
  }

  const getUserList = async () => {
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + userInfo.access_token,
      },
      body: JSON.stringify(form),
    };    
    await fetch(redirectUri + "/getuserlist", options)
    .then((res) => res.json())
    .then((res) => {
      let userOptions = [];
      res.values.sort((a, b) => a.name.localeCompare(b.name)).map((value) => 
        userOptions.push({
          label: value.name,
          value: value.email
        })
      );
      setUsers(userOptions);
    });
  }
  
  useEffect(() => {
    getData();
    // if (userInfo.role === 'Admin') {
    //   getUserList();
    // }
  }, [form]);
  
  useEffect(() => {
    emptyArray();
  }, [currentPage]);

  useEffect(() => {
    const setting = {
      startDate: startDate,
      endDate: endDate,
      sortColumn: sortColumn,
      sortType: sortType
    };    
    localStorage.setItem('tableSetting', JSON.stringify(setting));
  }, [startDate, endDate, sortColumn, sortType]);

  const categorize = (mimeType) => {
    const imageRegExp = /^image\//;
    const audioRegExp = /^audio\//;
    const videoRegExp = /^video\//;
    const archiveRegExp = /^(application\/zip|application\/x-zip-compressed|application\/x-tar|application\/x-gzip|application\/x-bzip2|application\/rar|application\/x-7z-compressed)/;
    const spreadsheetRegExp = /^application\/vnd\.openxmlformats-officedocument\.spreadsheetml/;
    const pdfRegExp = /^(application\/pdf)/;
    const documentRegExp = /^(application\/vnd\.google-apps\.document|application\/msword|application\/vnd\.openxmlformats-officedocument\.wordprocessingml|application\/pdf)/;
    const presentationRegExp = /^(application\/vnd\.ms-powerpoint|application\/vnd\.openxmlformats-officedocument\.presentationml|application\/vnd\.ms-powerpoint\.slideshow)/;
    const folderRegExp = /^(application\/vnd\.google-apps\.folder)/;

    let icon = '';
    let name = '';
  
    if (imageRegExp.test(mimeType)) {
      icon = (<FontAwesomeIcon icon={faFileImage} className="me-2"/>);
      name = 'Image';
    } else if (audioRegExp.test(mimeType)) {
      icon = (<FontAwesomeIcon icon={faFileAudio} className="me-2"/>);
      name = 'Audio';
    } else if (videoRegExp.test(mimeType)) {
      icon = (<FontAwesomeIcon icon={faFileVideo} className="me-2"/>);
      name = 'Video';
    } else if (archiveRegExp.test(mimeType)) {
      icon = (<FontAwesomeIcon icon={faFileZipper} className="me-2"/>);
      name = 'Archive';
    } else if (pdfRegExp.test(mimeType)) {
      icon = (<FontAwesomeIcon icon={faFilePdf} className="me-2"/>);
      name = 'PDF';
    } else if (spreadsheetRegExp.test(mimeType)) {
      icon = (<FontAwesomeIcon icon={faFileLines} className="me-2"/>);
      name = 'Spreadsheet';
    } else if (documentRegExp.test(mimeType)) {
      icon = (<FontAwesomeIcon icon={faFileLines} className="me-2"/>);
      name = 'Document';
    } else if (presentationRegExp.test(mimeType)) {
      icon = (<FontAwesomeIcon icon={faFileLines} className="me-2"/>);
      name = 'Presentation';
    } else if (folderRegExp.test(mimeType)) {
      icon = (<FontAwesomeIcon icon={faFolder} className="me-2"/>);
      name = 'Folder';
    } else {
      icon = (<FontAwesomeIcon icon={faFileLines} className="me-2"/>);
      name = 'File';
    }
    return {
      typeIcon: icon, 
      typeName: name
    }
  }

  const sortTable = (a, b) => {    
    const rowA = a[sortColumn] || '';
    const rowB = b[sortColumn] || '';
    const comparison = rowA.localeCompare(rowB);
    if (comparison !== 0) {
      return sortType === 'asc' ? comparison : -comparison;
    } else {
      if (sortColumn === 'typeName') {
        const customA = a.type.typeName || 0;
        const customB = b.type.typeName || 0;      
        if (customA < customB) return sortType === 'asc' ? -1 : 1;
        else if (customA > customB) return sortType === 'asc' ? 1 : -1;
        else return 0;
      } else {
        const customA = a.permissions.length || 0;
        const customB = b.permissions.length || 0;      
        if (customA < customB) return sortType === 'asc' ? -1 : 1;
        else if (customA > customB) return sortType === 'asc' ? 1 : -1;
        else return 0;
      }
    }

  }

  const sortPermission = (a, b) => {
    if (a.role === 'owner' && b.role !== 'owner') return -1;
    if (a.role !== 'owner' && b.role === 'owner') return 1;
    return a.role.localeCompare(b.role);
  }

  const sort = (column) => {
    setSelectedFiles([]);
    let type = 'asc';
    if (column === sortColumn) {
      type = sortType === 'asc' ? 'desc' : 'asc';
    }
    setSortColumn(column);
    setSortType(type);
  }

  const filter = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);
    const diff = endMoment.diff(startMoment, "days");
    if (diff > 90) {
      alert('Max 90 days');
    } else {
      setTableData([]);
      setCurrentPage(1);
      setForm({ ...form, ...{startDate: startDate, endDate: endDate, nextPageToken: ''} });
    }
    setIsLoading(false);
  }

  const reset = () => {
    let start = moment().subtract(90, "days").format("YYYY-MM-DD");
    let end = moment().format("YYYY-MM-DD");
    setSortColumn('createdTime');
    setSortType('desc');
    setStartDate(start);
    setEndDate(end);
    setTableData([]);
    setCurrentPage(1);
    setForm({ ...form, ...{startDate: start, endDate: end, nextPageToken: ''} });
  }

  const emptyArray = () => {
    setSelectedFiles([]);
  };

  const checkAll = () => {
    let count = selectedFiles.length;
    emptyArray();

    if (count !== currentItems.length) {
      setSelectedFiles(currentItems);
    }
  }

  const checkOne = (i) => {
    let selection = currentItems[i];
    if (selectedFiles.some((selected) => selected.id === selection.id)) {
      setSelectedFiles(current =>
        current.filter(data => {
          return data.id !== selection.id;
        })
      );
    } else {
      setSelectedFiles(current => [...current, selection])
    }
  }

  const showModal = (data) => {
    setShowInfo('d-block');
    setShowRemove('d-none');
    setModal(data);
    setShow(true);
  }
  const closeModal = () => setShow(false);

  const confirmRemove = (permission) => {
    setUser(permission);
    setShowInfo('d-none');
    setShowRemove('d-block');
  }

  const cancelRemove = () => {
    setShowInfo('d-block');
    setShowRemove('d-none');
  }

  const removeData = async () => {
    const userData = {
      accessToken: userInfo.access_token,
      email: form.impersonate ? form.impersonate : '',
      data: [
        {
          fileId: modal.id,
          permissionIds: [
            user.id
          ]
        }
      ]
    }
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + userInfo.access_token,
      },
      body: JSON.stringify(userData)
    };    
    await fetch(redirectUri + `${form.impersonate!=='' ? "/removeaccessimpersonated" : "/removeaccess"}`, options)
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        window.location.href = './user-shared-to-anyone';
      }
    });
  }

  const showModalMulti = () => {
    let sendData = {
      accessToken: userInfo.access_token,
      email: form.impersonate ? form.impersonate : '',
      data: []
    }
    for (let i in selectedFiles) {
      let fileData = {
        fileId: selectedFiles[i].id,
        permissionIds: []
      };
      let permissionData = selectedFiles[i].permissions.filter(data => {
        return data.role !== 'owner';
      });

      if (permissionData.length > 0) {
        let permissionIds = [];
        for (let j in permissionData) permissionIds.push(permissionData[j].id);
        fileData.permissionIds = permissionIds;
      }
      sendData.data.push(fileData);
    }
    setModalMulti(sendData);
    setShowMulti(true);
  }
  const closeModalMulti = () => setShowMulti(false);

  const removeMulti = async () => {
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + userInfo.access_token,
      },
      body: JSON.stringify(modalMulti)
    };    
    await fetch(redirectUri + `${form.impersonate!=='' ? "/removeaccessimpersonated" : "/removeaccess"}`, options)
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        window.location.href = './user-shared-to-anyone';
      }
    });
  }

  const itemsPerPage = 100;
  const indexOfFirstItem = (currentPage - 1) * itemsPerPage;
  const indexOfLastItem = Math.min(currentPage * itemsPerPage, tableData.length);
  const currentItems = tableData.sort(sortTable).slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = tableData.length > 0 ? Math.ceil(tableData.length / itemsPerPage) : 1;

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const closeModalUsers = () => setShowUsers(false);

  const handleSelectUser = () => {
    if (selectedUser) {
      let newUserInfo = {
        sub: userInfo.sub,
        name: userInfo.name,
        given_name: userInfo.given_name,
        family_name: userInfo.family_name,
        picture: userInfo.picture,
        email: userInfo.email,
        email_verified: userInfo.email_verified,
        hd: userInfo.hd,
        access_token: userInfo.access_token,
        role: userInfo.role,
        impersonate_email: selectedUser.value,
        impersonate_name: selectedUser.label
      }
      localStorage.setItem('userInfo', JSON.stringify(newUserInfo));
      window.location.reload();
    } else {
      setShowUsers(false);
    }
  }

  const handleRoleChange = (role) => {
    if (role === 'Administrator') {
      setShowUsers(true);
    } else {
      let newUserInfo = {
        sub: userInfo.sub,
        name: userInfo.name,
        given_name: userInfo.given_name,
        family_name: userInfo.family_name,
        picture: userInfo.picture,
        email: userInfo.email,
        email_verified: userInfo.email_verified,
        hd: userInfo.hd,
        access_token: userInfo.access_token,
        role: userInfo.role
      }
      localStorage.setItem('userInfo', JSON.stringify(newUserInfo));
      window.location.reload();
    }
  };

  const logout = () => {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('tableSetting');
    window.location.href = "./login";
  }

  return (
    <div id="page-shared-to-anyone" className="bg-blue">
      <Navbar className="py-4" key="xl" expand="xl">
        <Container>
          <Navbar.Brand href="./my-files">
            <Image src="/logo.png" alt="Logo" width={120}/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvas-nav" />
          <Navbar.Offcanvas
            id="offcanvas-nav"
            aria-labelledby="offcanvas-label"
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvas-label">
                <Image src="/logo.png" alt="Menu Logo" width={80}/>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1">
                <Nav.Link href="./my-files">My Files</Nav.Link>
                <Nav.Link href="./shared-to-external">Shared to External</Nav.Link>
                <Nav.Link href="./shared-to-anyone">Shared to Anyone</Nav.Link>
                {userInfo.role === 'Admin' ? (
                  <Nav.Link className="dropdown-avatar active" href="./administrator"><FontAwesomeIcon icon={faUserShield}/></Nav.Link>
                ) : ''}
                <Dropdown>
                  <Dropdown.Toggle className="dropdown-avatar" variant="outline-dark">
                    {userInfo.name}
                    <Image className="ms-2" src={userInfo.picture} alt="Avatar" rounded width={30} referrerPolicy="no-referrer"/>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end">
                    <Dropdown.Item onClick={logout}>Log Out</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      <Container className="overflow-hidden">
        <div className="page-main">
          <h1 className="mb-4 d-flex align-items-center">
            <img className="rounded me-2" width={35} src={userInfo.impersonate_avatar} alt={userInfo.impersonate_name}/>
            {userInfo.impersonate_name} &gt; 
            <Dropdown className="ms-2">
              <Dropdown.Toggle className="dropdown-link">Shared to Anyone</Dropdown.Toggle>
              <Dropdown.Menu align="end">
                <Dropdown.Item href="./user-shared-to-external">Shared to External</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </h1>
          <Row>
            <Col md={2}>
              <ul className="sidebar">
                <li><a href="./administrator"><FontAwesomeIcon className="me-2" icon={faArrowLeft}/> User Files</a></li>
              </ul>
            </Col>
            <Col md={10}>
              <div className="page-filter mb-3">
                <Form className="flex-auto" onSubmit={filter}>
                  <Row className="align-items-center">
                    <Col sm={4}>
                      <Form.Label htmlFor="start-date" visuallyHidden>From</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>From</InputGroup.Text>
                        <Form.Control id="start-date" type="date" name="start_date" value={startDate} onChange={(e) => {setStartDate(e.target.value)}}/>
                      </InputGroup>
                    </Col>
                    <Col sm={4}>
                      <Form.Label htmlFor="end-date" visuallyHidden>To</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>To</InputGroup.Text>
                        <Form.Control id="end-date" type="date" name="end_date" value={endDate} onChange={(e) => {setEndDate(e.target.value)}}/>
                      </InputGroup>
                    </Col>
                    <Col xs="auto">
                      {
                        isLoading ? (
                          <Button type="submit" variant="secondary" disabled>
                            <Spinner className="me-2" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                            Loading...
                          </Button>
                        ) : (
                          <Button type="submit" variant="primary">
                            <FontAwesomeIcon icon={faFilter} className="me-2"/>
                            Filter
                          </Button>
                        )
                      }
                      {
                        !(sortColumn === 'createdTime' && sortType === 'desc' && startDate === moment().subtract(90, "days").format("YYYY-MM-DD") && endDate === moment().format("YYYY-MM-DD")) && (
                          <Button className="ms-2" variant="outline-danger" onClick={() => reset()}>
                            <FontAwesomeIcon icon={faRotateLeft} className="me-2"/>
                            Reset
                          </Button>
                        )
                      }
                    </Col>
                  </Row>
                </Form>
                <div className="text-right">
                  Showing {tableData.length > 0 ? (indexOfFirstItem + 1) : 0}-{indexOfLastItem} of {tableData.length}
                </div>
              </div>
              {
                tableData.length > 0 ? (
                  <Table className="table-data" responsive hover>
                    <thead>
                      <tr>
                        <th style={{width: '50px'}}>
                          <input className="form-check-input" type="checkbox" name="check_head" onChange={() => checkAll()} checked={selectedFiles.length && (selectedFiles.length === currentItems.length)}/>
                        </th>
                        <th style={{width: '300px'}} className="sortable" onClick={() => sort('name')}>
                          Name
                          {
                            sortColumn === 'name' && (
                              <FontAwesomeIcon className="ms-2" icon={sortType === 'asc' ? faCaretUp : faCaretDown}/>
                            )
                          }
                        </th>
                        <th className="sortable" onClick={() => sort('typeName')}>
                          Type
                          {
                            sortColumn === 'typeName' && (
                              <FontAwesomeIcon className="ms-2" icon={sortType === 'asc' ? faCaretUp : faCaretDown}/>
                            )
                          }
                        </th>
                        <th className="sortable" onClick={() => sort('createdTime')}>
                          Created
                          {
                            sortColumn === 'createdTime' && (
                              <FontAwesomeIcon className="ms-2" icon={sortType === 'asc' ? faCaretUp : faCaretDown}/>
                            )
                          }
                        </th>
                        <th className="sortable" onClick={() => sort('modifiedTime')}>
                          Modified
                          {
                            sortColumn === 'modifiedTime' && (
                              <FontAwesomeIcon className="ms-2" icon={sortType === 'asc' ? faCaretUp : faCaretDown}/>
                            )
                          }
                        </th>
                        <th className="sortable" onClick={() => sort('permissionCount')}>
                          Shared to
                          {
                            sortColumn === 'permissionCount' && (
                              <FontAwesomeIcon className="ms-2" icon={sortType === 'asc' ? faCaretUp : faCaretDown}/>
                            )
                          }
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        currentItems
                        .map((data, i) => {
                          return(
                            <tr key={"data-" + i}>
                              <td>
                                <input className="form-check-input" type="checkbox" name="check_body[]" onChange={() => checkOne(i)} checked={selectedFiles.some((selected) => JSON.stringify(selected) === JSON.stringify(data))}/>
                              </td>
                              <td>
                                <a href={data.webViewLink} target="_blank" rel="noreferrer">
                                  <span>{data.name}</span>
                                  <FontAwesomeIcon className="ms-2" icon={faArrowUpRightFromSquare}/>
                                </a>
                              </td>
                              <td>
                                {data.type.typeIcon}
                                {data.type.typeName}
                              </td>
                              <td>{moment(data.createdTime).format("DD MMM YYYY")}</td>
                              <td>{moment(data.modifiedTime).format("DD MMM YYYY")}</td>
                              <td>Anyone</td>
                              <td align="right">
                                <div className="btn-share" onClick={() => showModal(data)}>
                                  <FontAwesomeIcon icon={faEllipsisH}/>
                                </div>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>
                ) : (
                  <div className="no-result">
                    {isError ? (
                      <div className="no-result-box">
                        <FontAwesomeIcon icon={faTriangleExclamation}/>
                        <p>Cannot retrieve user's data</p>
                      </div>
                    ) : (
                      <div className="no-result-box">
                        <FontAwesomeIcon icon={faFile}/>
                        <p>There are no result to display</p>
                      </div>
                    )}
                  </div>
                )
              }
              <div className="row mt-2">
                {
                  selectedFiles.length > 0 ? (
                    <div className="col-md-6">
                      Selected {selectedFiles.length} of {tableData.length}
                      <Button className="ms-4" variant="danger" onClick={() => showModalMulti()}>Remove Access</Button>
                    </div>
                  ) : (
                    <div className="col-md-6">&nbsp;</div>
                  )
                }
                <div className="col-md-6 text-end">
                  <button className="btn btn-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Prev</button>
                  <button className="btn btn-link" disabled>{currentPage}</button>
                  <button className="btn btn-link pe-0" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Modal show={show} onHide={closeModal}>
        <Modal.Header closeButton>
          {modal.type !== undefined && modal.type.typeIcon}
          <span>{modal.name}</span>
        </Modal.Header>
        <Modal.Body>
          <div id="modal-info" className={showInfo}>
            <p className="mb-3">People with Access</p>
            {
              show === true && (
                modal.permissions
                .sort(sortPermission)
                .filter(user => {
                  if (user.id === 'anyoneWithLink') return false;
                  return true;
                })
                .map((permission, i) => {
                  return (
                    <div className="permission-box" key={"permission-" + i}>
                      <div className="permission-avatar">
                        {
                          permission.type === 'user' ? (
                            <Image src={permission.photoLink} alt="Avatar" roundedCircle width={50} referrerPolicy="no-referrer"/>
                          ) : (
                            <Image src="/group.png" alt="Avatar" roundedCircle width={50} referrerPolicy="no-referrer"/>
                          )
                        }
                      </div>
                      <div className="permission-info">
                        <p>{permission.displayName}</p>
                        <small>{permission.emailAddress}</small>
                      </div>
                      {
                        permission.role === 'owner' ? (
                          <Dropdown>
                            <Dropdown.Toggle variant="outline-light" disabled>{permission.role}</Dropdown.Toggle>
                          </Dropdown>
                        ) : (
                          <Dropdown>
                            <Dropdown.Toggle variant="outline-light">{permission.role}</Dropdown.Toggle>
                            <Dropdown.Menu align="end">
                              <Dropdown.Item onClick={() => confirmRemove(permission)}>
                                <FontAwesomeIcon icon={faTrash}/>
                                Remove Access
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        )
                      }
                    </div>
                  )
                })
              )
            }
          </div>
          <div className={showRemove}>
            <p className="mb-3">Are you sure want to remove access?</p>
            {
              user.id === 'anyoneWithLink' ? (
                <div className="access-type">
                  <div className="access-icon">
                    <FontAwesomeIcon icon={faEarthAmericas}/>
                  </div>
                  <div className="access-info">
                    <p>Anyone with the link</p>
                    <small>Anyone on the internet with the link can view</small>
                  </div>
                </div>
              ) : (
                <div className="permission-box">
                  <div className="permission-avatar">
                    {
                      user.type === 'user' ? (
                        <Image src={user.photoLink ? user.photoLink : "/avatar.png"} alt="Avatar" roundedCircle width={50} referrerPolicy="no-referrer"/>
                      ) : (
                        <Image src="/group.png" alt="Avatar" roundedCircle width={50} referrerPolicy="no-referrer"/>
                      )
                    }
                  </div>
                  <div className="permission-info">
                    <p>{user.displayName ? user.displayName : 'Deleted User'}</p>
                    <small>{user.emailAddress ? user.emailAddress : '-'}</small>
                  </div>
                </div>
              )
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className={showInfo}>
            <p className="mb-2">General Access</p>
            <div className="access-type">
              <div className="access-icon">
                <FontAwesomeIcon icon={faEarthAmericas}/>
              </div>
              <div className="access-info">
                <p>Anyone with the link</p>
                <small>Anyone on the internet with the link can view</small>
              </div>
              {
                show === true && (
                  modal.permissions
                  .filter(user => {
                    if (user.id === 'anyoneWithLink') return true;
                    return false;
                  })
                  .map((permission, i) => {
                    return (
                      <Dropdown key={"remove-" + i}>
                        <Dropdown.Toggle variant="outline-light">{permission.role}</Dropdown.Toggle>
                        <Dropdown.Menu align="end">
                          <Dropdown.Item onClick={() => confirmRemove(permission)}>
                            <FontAwesomeIcon icon={faTrash}/>
                            Remove Access
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )
                  })
                )
              }
            </div>
          </div>
          <div className={showRemove}>
            <div className="modal-remove">
              <Button variant="link" className="me-2" onClick={() => cancelRemove()}>Cancel</Button>
              <Button variant="danger" onClick={() => removeData()}>Remove</Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={showMulti} onHide={closeModalMulti}>
        <Modal.Header closeButton>
          Remove Access
        </Modal.Header>
        <Modal.Body>
          <p className="mb-3">Are you sure want to remove access?</p>
          { 
            showMulti === true && (
              <div className="permission-box">
                <div className="permission-icon">
                  <FontAwesomeIcon icon={faTrash}/>
                </div>
                <div className="permission-info">
                  <p>{modalMulti.data.length} {modalMulti.data.length > 1 ? ' Files' : ' File'}</p>
                  <small>
                    {
                      modalMulti.data.reduce((count, item) => {
                        return count + item.permissionIds.length;
                      }, 0)
                    }
                    &nbsp;
                    User permissions
                  </small>
                </div>
              </div>
            )
          }
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-remove">
            <Button variant="link" className="me-2" onClick={() => closeModalMulti()}>Cancel</Button>
            <Button variant="danger" onClick={() => removeMulti()}>Remove</Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={showUsers} onHide={closeModalUsers}>
        <Modal.Header closeButton>
          Select User
        </Modal.Header>
        <Modal.Body>
          <Select options={users} onChange={(value) => setSelectedUser(value)}/>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-remove">
            <Button variant="link" className="me-2" onClick={closeModalUsers}>Cancel</Button>
            <Button variant="primary" onClick={handleSelectUser}>Select</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserAnyone;
