import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar, Dropdown, Offcanvas, Row, Col, Image, Table, Spinner, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faFile, faSearch, faTriangleExclamation, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { redirectUri } from "./config/global";

const Administrator = () => {
	const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const [form, setForm] = useState({
    accessToken: userInfo.access_token,
    email: userInfo.email,
    domain: userInfo.hd
  });

  const [users, setUsers] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState('User Files');
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState('');

  const getUserList = async () => {
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + userInfo.access_token,
      },
      body: JSON.stringify(form),
    };    
    await fetch(redirectUri + "/getuserlist", options)
    .then((res) => res.json())
    .then((res) => {
      let userOptions = [];
      res.values.sort((a, b) => a.name.localeCompare(b.name)).map((value) => 
        userOptions.push({
          name: value.name,
          email: value.email,
          avatar: value.avatar,
          search: value.name + " " + value.email
        })
      );
      setIsLoading(false);
      setUsers(userOptions);
    });
  }
  
  useEffect(() => {
    getUserList();
  }, []);

  const selectMenu = (e, menu) => {
    e.preventDefault();
    setSelectedMenu(menu);
  }

  const selectUser = (user, type) => {
    let newUserInfo = {
      sub: userInfo.sub,
      name: userInfo.name,
      given_name: userInfo.given_name,
      family_name: userInfo.family_name,
      picture: userInfo.picture,
      email: userInfo.email,
      email_verified: userInfo.email_verified,
      hd: userInfo.hd,
      access_token: userInfo.access_token,
      role: userInfo.role,
      impersonate_name: user.name,
      impersonate_email: user.email,
      impersonate_avatar: user.avatar,
      impersonate_type: type
    }
    localStorage.setItem('userInfo', JSON.stringify(newUserInfo));
    window.location.href = './user-shared-to-' + type.toLowerCase();
  }

  const logout = () => {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('tableSetting');
    window.location.href = "./login";
  }

  return (
    <div id="page-my-files" className="bg-blue">
      <Navbar className="py-4" key="xl" expand="xl">
        <Container>
          <Navbar.Brand href="./my-files">
            <Image src="/logo.png" alt="Logo" width={120}/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvas-nav" />
          <Navbar.Offcanvas
            id="offcanvas-nav"
            aria-labelledby="offcanvas-label"
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvas-label">
                <Image src="/logo.png" alt="Menu Logo" width={80}/>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1">
                <Nav.Link href="./my-files">My Files</Nav.Link>
                <Nav.Link href="./shared-to-external">Shared to External</Nav.Link>
                <Nav.Link href="./shared-to-anyone">Shared to Anyone</Nav.Link>
                {userInfo.role === 'Admin' ? (
                  <Nav.Link className="dropdown-avatar active" href="./administrator"><FontAwesomeIcon icon={faUserShield}/></Nav.Link>
                ) : ''}
                <Dropdown>
                  <Dropdown.Toggle className="dropdown-avatar" variant="outline-dark">
                    {userInfo.name}
                    <Image className="ms-2" src={userInfo.picture} alt="Avatar" rounded width={30} referrerPolicy="no-referrer"/>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end">
                    <Dropdown.Item onClick={logout}>Log Out</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      <Container className="overflow-hidden">
        <div className="page-main">
          <h1 className="mb-4">Administrator</h1>
          <Row>
            <Col md={2}>
              <ul className="sidebar">
                <li><a className={selectedMenu === 'User Files' ? "active" : ""} href="./administrator" onClick={(e) => selectMenu(e, 'User Files')}>User Files</a></li>
                {/* <li><a className={selectedMenu === 'Organization' ? "active" : ""} href="./administrator" onClick={(e) => selectMenu(e, 'Organization')}>Organization</a></li> */}
                {/* <li><a className={selectedMenu === 'Subscription' ? "active" : ""} href="./administrator" onClick={(e) => selectMenu(e, 'Subscription')}>Subscription</a></li> */}
              </ul>
            </Col>
            <Col md={10}>
              {
                selectedMenu === 'User Files' ? (
                  <div>
                    {
                      users.length > 0 ? (
                        <div>
                          <Form className="flex-auto">
                            <Row className="align-items-center">
                              <Col sm={4}>
                                <Form.Label htmlFor="search" visuallyHidden>Search</Form.Label>
                                <InputGroup>
                                  <InputGroup.Text className="search"><FontAwesomeIcon icon={faSearch}/></InputGroup.Text>
                                  <Form.Control id="search" type="text" name="search" value={filter} placeholder="Search User..." onChange={(e) => {setFilter(e.target.value)}}/>
                                </InputGroup>
                              </Col>
                            </Row>
                          </Form>
                          <Table className="table-data mt-3" responsive hover>
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                users
                                .filter((data) => data.search.includes(filter))
                                .map((data, i) => {
                                  return(
                                    <tr key={"data-" + i}>
                                      <td>
                                        <img className="rounded me-3" src={data.avatar} width={30}/>
                                        {data.name}
                                      </td>
                                      <td>{data.email}</td>
                                      <td align="right">
                                        <div className="btn-share">                                        
                                          <Dropdown>
                                            <Dropdown.Toggle className="dropdown-icon"><FontAwesomeIcon icon={faEllipsisH}/></Dropdown.Toggle>
                                            <Dropdown.Menu align="end">
                                              <Dropdown.Item onClick={() => selectUser(data, 'External')}>Shared to External</Dropdown.Item>
                                              <Dropdown.Item onClick={() => selectUser(data, 'Anyone')}>Shared to Anyone</Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>                                        
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </Table>
                        </div>
                      ) : (
                        <div className="no-result">
                          {isError ? (
                            <div className="no-result-box">
                              <FontAwesomeIcon icon={faTriangleExclamation}/>
                              <p>Cannot retrieve user list</p>
                            </div>
                          ) : (
                            isLoading ? (
                              <div className="no-result-box">
                                <Spinner/>
                                <p>Loading</p>
                              </div>
                            ) : (
                              <div className="no-result-box">
                                <FontAwesomeIcon icon={faFile}/>
                                <p>There are no result to display</p>
                              </div>
                            )
                          )}
                        </div>
                      )
                    }
                  </div>
                ) : ''
              }
              {
                selectedMenu === 'Organization' ? (
                  <div className="no-result">
                    <div className="no-result-box">
                      <FontAwesomeIcon icon={faFile}/>
                      <p>There are no result to display</p>
                    </div>
                  </div>
                ) : ''
              }
              {
                selectedMenu === 'Subscription' ? (
                  <div className="no-result">
                    <div className="no-result-box">
                      <FontAwesomeIcon icon={faFile}/>
                      <p>There are no result to display</p>
                    </div>
                  </div>
                ) : ''
              }
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Administrator;
