import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { useGoogleLogin } from "@react-oauth/google";
import { redirectUri } from "./config/global";

const Login = () => {
	const userInfo = JSON.parse(localStorage.getItem('userInfo'));
	
  const getAdminPrivilege = async (accessToken) => {
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + accessToken,
      },
      body: JSON.stringify({
				accessToken: accessToken,
			}),
    };
    return await fetch(redirectUri + "/getadminprivilege", options)
    .then((res) => res.json())
    .then((res) => {
			return res.status === 200 ? 'Admin' : 'User';
    });
  }

  const handleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      let url = "https://www.googleapis.com/oauth2/v3/userinfo";
      let options = {
        method: "GET",
        headers: {
					"Authorization": "Bearer " + tokenResponse.access_token
				},
        redirect: "follow",
      };
      fetch(url, options)
			.then((res) => res.json())
			.then(async (res) => {
				let role = await getAdminPrivilege(tokenResponse.access_token);
				let userInfo = {
					sub: res.sub,
					name: res.name,
					given_name: res.given_name,
					family_name: res.family_name,
					picture: res.picture,
					email: res.email,
					email_verified: res.email_verified,
					hd: res.hd,
					access_token: tokenResponse.access_token,
					role: role
				};
				localStorage.setItem('userInfo', JSON.stringify(userInfo));
				window.location.href = './my-files';
			});
    },
    scope: "https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/admin.reports.audit.readonly https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/admin.reports.usage.readonly https://www.googleapis.com/auth/admin.directory.user.readonly",
    redirect_uri: redirectUri
  });

	if (userInfo) {
		window.location.href = './my-files';
	} else {
		return (
			<div id="page-login">
				<Container fluid="md">
					<Row>
						<Col className="login-col" sm={6}>
							<div className="login-col-center">
								<Image className="mb-5" src="/logo.png" alt="Logo" width={150}/>
								<h1 className="mb-5">Welcome back!</h1>
								<Button className="btn-login" onClick={handleLogin} variant="light">
									<Image className="me-3" src="/google.png" alt="Google" width={25}/>
									Login with Google
								</Button>
							</div>
						</Col>
						<Col className="login-col right" sm={6}>
							<div className="login-col-center">
								<h2 className="mb-5">Effortlessly Monitor Your Shared Google Drive Files Anytime. Anywhere.</h2>
								<Image className="img-fluid" src="/login-bg.png" alt="Background"/>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
};

export default Login;
